var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container page__inner"},[(_vm.isLoading)?_c('div',{staticClass:"d-flex align-items-center justify-content-center p5",staticStyle:{"width":"100%"}},[_vm._m(0),_c('span',{staticClass:"text--black text--600 text--medium d-inline-flex ml-4"},[_vm._v("Please wait")])]):_vm._e(),(!_vm.isLoading)?_c('main',{staticClass:"page__content2",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"page__head d-flex"},[_c('div',{staticClass:"page__head__container align-items-start justify-content-between"},[_c('h2',[_vm._v(_vm._s(_vm.userDetails.userFirstName)+" "+_vm._s(_vm.userDetails.userLastName))]),_c('div',{staticClass:"page__head__actions"})])]),_c('div',{staticClass:"page__body"},[_c('div',[_vm._m(1),_c('div',{staticClass:"tab-content",attrs:{"id":"page-TabsContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"pills-details","role":"tabpanel","aria-labelledby":"pills-details-tab"}},[_c('hr'),_c('div',[_c('div',{staticClass:"row mt5"},[_c('div',{staticClass:"col-6"},[_c('generalUserInfo',{attrs:{"userInfo":_vm.userDetails}})],1),_c('div',{staticClass:"w-50"},[_c('userInformation',{attrs:{"userInfo":_vm.userDetails}})],1),_c('div',{staticClass:"toaster show"},[(_vm.alert.show)?_c('div',{staticClass:"toaster__container",class:{
                        'toaster--success': _vm.alert.status === 'success',
                        'toaster--error': _vm.alert.status === 'fail',
                        'toaster--warn': _vm.alert.status === 'warn',
                      }},[_c('div',{staticClass:"text-right"},[_c('span',{staticClass:"fa fa-times",on:{"click":function($event){_vm.alert.show = false}}})]),_c('div',[_c('span',{staticClass:"d-block text--small text--600 text--white"},[_vm._v(_vm._s(_vm.alert.title))]),_c('span',{staticClass:"d-block text--tiny text--400 text--white"},[_vm._v(_vm._s(_vm.alert.description))])])]):_vm._e()])])])])])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page__body__header"},[_c('ul',{staticClass:"nav page__tabs page__tabs2",attrs:{"id":"page-Tabs","role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active text--capital",attrs:{"id":"pills-details-tab","data-toggle":"tab","href":"#pills-details","role":"tab","aria-controls":"pills-details","aria-selected":"true"}},[_vm._v("details")])])])])
}]

export { render, staticRenderFns }