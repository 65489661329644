<template>
  <div class="container page__inner">
    <div
      class="d-flex align-items-center justify-content-center p5"
      v-if="isLoading"
      style="width: 100%"
    >
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span class="text--black text--600 text--medium d-inline-flex ml-4"
        >Please wait</span
      >
    </div>

    <!-- <CustomerAside
      v-if="!isLoading"
      v-bind:customer-name="accountFullName"
      v-bind:customer-key="accountDetails.accountHolderKey"
      :customerLoanDeposit="customerLoanDeposits"
      @imageAlert="imageAlert"
    ></CustomerAside> -->

    <main class="page__content2" v-if="!isLoading" style="width: 100%;">
      <div class="page__head d-flex">
        <div
          class="
            page__head__container
            align-items-start
            justify-content-between
          "
        >
          <h2>{{ userDetails.userFirstName }} {{ userDetails.userLastName }}</h2>
          <div class="page__head__actions">
            <!-- <div class="d-flex">
              <button
                type="button"
                class="
                  mx-2
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
                @click="openMaturityModal"
                v-if="
                  (this.isSavingPlan &&
                    !this.maturityDateStarted &&
                    !this.isPendingState & !this.isLocked &&
                    !this.isApprovedState &&
                    !this.isWithdrawn &&
                    !this.isRejected &&
                    !this.isDormant &&
                    !this.isMatured &&
                    !this.isClosed) ||
                  (this.isSavingsAccountPlan &&
                    this.isActive &&
                    !this.maturityDateStarted &&
                    !this.maturityDateStarted) &&
                    this.userCanBeginMaturity
                "
              >
                <span class="d-inline-flex mr-2" data-modal="#addDepositModal"
                  >Begin Maturity Period</span
                >
              </button>
              <button
                type="button"
                class="
                  mx-2
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
                @click="openDepositModal"
                v-if="
                  (this.isSavingPlan &&
                    !this.isPendingState &&
                    !this.isLocked &&
                    !this.isWithdrawn &&
                    !this.isRejected &&
                    !this.isMatured &&
                    !this.isClosed) ||
                  (this.isSavingsAccountPlan &&
                    (this.isApprovedState || this.isActive)) ||
                  (this.savingsAccountKey && this.isDormant) &&
                  this.userCanMakeDeposit
                "
              >
                <span class="d-inline-flex mr-2" data-modal="#addDepositModal"
                  >deposit</span
                >
              </button>
              <button
                type="button"
                class="
                  mx-2
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
                @click="openWithdrawModal"
                v-if="
                  !this.isPendingState &&
                  !this.isApprovedState &&
                  !this.isLocked &&
                  !this.isWithdrawn &&
                  !this.isRejected &&
                  !this.isClosed
                "
              >
                <span class="d-inline-flex mr-2">withdraw</span>
              </button>
              <button
                type="button"
                class="
                  mx-2
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
                @click="openMakeTransferModal"
                v-if="
                  !this.isPendingState &&
                  !this.isApprovedState &&
                  !this.isLocked &&
                  !this.isWithdrawn &&
                  !this.isRejected &&
                  !this.isClosed
                "
              >
                <span class="d-inline-flex mr-2">transfer</span>
              </button>
              <div class="align-self-center mx-2 dropdown" v-if="!this.isActive">
                <button
                  class="
                    button button--grey
                    text--capital text--small
                    d-flex
                    align-items-center
                  "
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Account Status
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#rejectModal"
                    @click="openCreateAccountModal(accountDetails)"
                    >Change Account Status</a
                  >
                </div>
              </div>
              <div class="align-self-center mx-2 dropdown">
                <button
                  class="
                    button button--grey
                    text--capital text--small
                    d-flex
                    align-items-center
                  "
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  report
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    @click="showDepositStatementModal"
                    >Account Statement</a
                  >
                </div>
              </div>
              <div class="align-self-center mx-2 dropdown">
                <button
                  class="
                    button button--grey
                    text--capital text--small
                    d-flex
                    align-items-center
                  "
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  more
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#pendingFeesModal"
                    v-if="
                      (this.isSavingPlan &&
                        !this.isApprovedState &&
                        !this.isPendingState &&
                        !this.isLocked &&
                        !this.isWithdrawn &&
                        !this.isRejected &&
                        !this.isMatured &&
                        !this.isClosed) ||
                      (this.isFixedAccount &&
                        this.isDormant &&
                        !this.isWithdrawn) ||
                      (this.isSavingsAccountPlan &&
                        !this.isLocked &&
                        !this.isClosed)
                    "
                    @click="openNoApplyFeeModal"
                    >Apply Fee</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#accruedInterestModal"
                    v-if="
                      (this.isSavingPlan &&
                        !this.isPendingState &&
                        !this.isLocked &&
                        !this.isWithdrawn &&
                        !this.isRejected &&
                        !this.isApprovedState &&
                        !this.isDormant &&
                        !this.isMatured &&
                        !this.isClosed) ||
                      (this.isSavingsAccountPlan &&
                        this.isActive &&
                        !this.isLocked)
                    "
                    @click="openApplyModal()"
                    >Apply Accrued Interest</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    @click="openAddFieldModal()"
                    >Add Field</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    @click="openEditAccountModal"
                    v-if="userCanEditAccount"
                    >Edit Account</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#recommendDepositModal"
                    @click="openRecommendedModal()"
                    v-if="
                      (!this.isLocked &&
                        !this.isWithdrawn &&
                        !this.isRejected &&
                        !this.isClosed &&
                        !this.isFixedAccount) ||
                      (this.isSavingsAccountPlan && this.isLocked)
                    "
                    >Set Recommended Deposit</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#maxWithdrawalModal"
                    @click="openMaxModal()"
                    v-if="
                      !this.isWithdrawn && !this.isRejected && !this.isClosed && !this.isFixedAccount
                    "
                    >Set Max Withdrawal Amount</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#changeHistoryModal"
                    @click="undoBeginMaturity"
                    v-if="
                      this.maturityDateStarted &&
                      !this.isLocked &&
                      !this.isRejected &&
                      !this.isMatured &&
                      !this.isClosed
                    "
                    >Undo Begin Maturity Period</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#lockAccountModal"
                    @click="openLockModal()"
                    v-if="
                      (!this.isPendingState &&
                        !this.isWithdrawn &&
                        !this.isRejected &&
                        !this.isMatured &&
                        !this.isClosed && !this.isFixedAccount) ||
                      (this.isActive && this.isLocked)
                    "
                    >{{
                      this.accountDetails.accountStatus === "LOCKED"
                        ? "Unlock Deposit Account"
                        : "Lock Deposit Account"
                    }}</a
                  >
                  <a v-if="!this.isFixedAccount" class="dropdown-item text--capital">View Change History</a>
                  <a
                    class="dropdown-item text--capital"
                    v-if="
                      (this.isPendingState ||
                        this.isApprovedState ||
                        this.isWithdrawn ||
                        this.isRejected ||
                        this.isClosed ||
                        !this.isLocked ||
                        !this.isRejected ||
                        !this.isActive ||
                        !this.isDormant ||
                        !this.isMatured) &&
                      this.isSavingsAccountPlan &&
                      !this.isLocked &&
                      !this.isActive &&
                      !this.isLocked &&
                      !this.isRejected &&
                      !this.isDormant &&
                      !this.isMatured
                    "
                    @click="deleteDepositAccount()"
                    >Delete</a
                  >
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="page__body">
        <div>
          <div class="page__body__header">
            <ul
              class="nav page__tabs page__tabs2"
              id="page-Tabs"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active text--capital"
                  id="pills-details-tab"
                  data-toggle="tab"
                  href="#pills-details"
                  role="tab"
                  aria-controls="pills-details"
                  aria-selected="true"
                  >details</a
                >
              </li>

              <!-- <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-transactions-tab"
                  data-toggle="tab"
                  href="#pills-transactions"
                  role="tab"
                  aria-controls="pills-transactions"
                  aria-selected="false"
                  >transactions</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-activities-tab"
                  data-toggle="tab"
                  href="#pills-activities"
                  role="tab"
                  aria-controls="pills-activities"
                  aria-selected="false"
                  >activities</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-attachments-tab"
                  data-toggle="tab"
                  href="#pills-attachments"
                  role="tab"
                  aria-controls="pills-attachments"
                  aria-selected="false"
                  >attachments</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-comments-tab"
                  data-toggle="tab"
                  href="#pills-comments"
                  role="tab"
                  aria-controls="pills-comments"
                  aria-selected="false"
                  >comments</a
                >
              </li> -->
            </ul>
          </div>

          <div class="tab-content" id="page-TabsContent">

            <div
              class="tab-pane fade show active"
              id="pills-details"
              role="tabpanel"
              aria-labelledby="pills-details-tab"
            >
              <hr />

              <div>

                <div class="row mt5">
                  <div class="col-6">

                    <generalUserInfo
                      :userInfo="userDetails"
                    />

                  </div>

                  <div class="w-50">

                    <userInformation
                      :userInfo="userDetails"
                    />

                  </div>

                    <!--ALERT NOTIFICATION-->
                    <div class="toaster show">
                      <div
                        class="toaster__container"
                        v-if="alert.show"
                        v-bind:class="{
                          'toaster--success': alert.status === 'success',
                          'toaster--error': alert.status === 'fail',
                          'toaster--warn': alert.status === 'warn',
                        }"
                      >
                        <div class="text-right">
                          <span class="fa fa-times" @click="alert.show = false"></span>
                        </div>
                        <div>
                          <span class="d-block text--small text--600 text--white">{{
                            alert.title
                          }}</span>
                          <span class="d-block text--tiny text--400 text--white">{{
                            alert.description
                          }}</span>
                        </div>
                      </div>
                    </div>

                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    </main>

  </div>
</template>
<script>
import moment from "moment";
import ApiService from "@/core/services/access.service";
import GeneralApiService from '@/core/services/general.service';

export default {
  name: "user_overview",

  components: {
    generalUserInfo: () =>
      import(
        /* WebpackChunkName: "generalUserInfo" */ "@/view/pages/Access/Users/component/generalUserInfo.vue"
      ),

    userInformation: () =>
      import(
        /* WebpackChunkName: "userInformation" */ "@/view/pages/Access/Users/component/userInformation.vue"
      ),
  },
  data() {
    return {
      userDetails: null,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Deposit Product has been created",
      },
      isLoading: false,
    };
  },
  computed: {
    
  },
  methods: {
    goToDashboard() {
      this.$router.push({ path: '/dashboard' });
    },
    getAccountDetails() {
      const userKey = this.$route.params.id;
      try {
        if (this.userKey !== '') {
          this.isLoading = true;
          ApiService.get(`ProleanUser/GetUser/${userKey}/reference`)
          .then((resp) => {
            this.isLoading = false;
            this.userDetails = resp.data.data;
          })
          .catch(e => {
            this.isLoading = false;
            this.alertError(e?.response?.data?.message)
          });
        }
      } catch(e) {
        this.alertError(e);
      } 
    },
    getBranchDetails() {
      this.branchName = "";
      GeneralApiService.get(
        `Branch/${this.branchKey}`
      ).then((resp) => {
        this.branchName = resp.data.data;
      });
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  created() {
    this.getAccountDetails();
  },
  mounted() {

  },
  /* eslint-disable */
  watch: {
    $route(to, from) {
      this.getAccountDetails();
    },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
    humanDate: function (date) {
      return date != null ? moment(date).fromNow() : "";
    },
    kb(val) {
      const sizes = ['bytes', 'KB', 'MB', 'GB'];
      if (val == 0) return '';
      const i = parseInt(Math.floor(Math.log(val) / Math.log(1024)), 10);
      if (i == 0) return `${val} ${sizes[i]}`
      return `${(val / (1024 ** i) ).toFixed(1)} ${sizes[i]}`;
    },
  },
};
</script>
<style scoped>
.text--capital span {
  font-size: 1.2rem;
}
.page__tabs2 {
  grid-template-columns: repeat(5, 1fr) !important;
}
.fitrem {
  min-width: 15rem;
}
.text--large {
  font-size: 2.4rem !important;
}
.input-file {
  opacity: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
.text--primary {
  color: #F58634 !important;
}
.text--opacity {
  opacity: 0.5;
}
</style>
